import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniq } from 'lodash'
import React, { memo, useState } from 'react'

import { Filter, Props as FilterProps } from './Filter'
import { Offer, Props as OfferProps } from './Offer'

export interface Props {
  filters?: FilterProps[]
  languageCode: string
  offers: OfferProps[]
}

export const OffersList = memo(function OffersList({
  filters,
  languageCode,
  offers,
}: Props) {
  if (offers.length < 1) {
    return null
  }

  const [filteredCategory, setFilteredCategory] = useState('all')

  return (
    <Container>
      {/* {filters ? (
        <Filters dial={2} row wrap>
          <Filter
            active={filteredCategory === 'all'}
            category="all"
            label={useVocabularyData('all', languageCode)}
            onClick={() => setFilteredCategory('all')}
          />
          {filters.map((item, index) => {
            const category = item.category
              ? item.category.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
              : undefined

            return (
              <Filter
                active={filteredCategory === category}
                key={index}
                {...item}
                onClick={(e) => {
                  setFilteredCategory(e.target.getAttribute('data-category'))
                }}
              />
            )
          })}
        </Filters>
      ) : null} */}
      <List row wrap space="between">
        {uniq(offers).map((item, index) => {
          const category = item.category
            ? item.category.replace(/[^a-zA-Z ]/g, '').toLocaleLowerCase()
            : undefined
          return (
            <Offer
              key={index}
              className={
                filteredCategory === category || filteredCategory === 'all'
                  ? 'visible'
                  : undefined
              }
              {...item}
            />
          )
        })}
      </List>
    </Container>
  )
})

const Container = styled.section`
  max-width: 71.25rem;
  margin: 9.75rem auto 10.5rem;
  padding: 0 1.5rem;

  @media (max-width: 1023px) {
    margin: 7.5rem auto;
  }
`

const Filters = styled(FlexBox)``

const List = styled(FlexBox)``
